import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/suspect-gifs/mast.gif'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const SuspectGifsPage = ({ data, path }) => {

    return (
        <Project
            title="Suspect GIFs"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                    This memory game is going to present six trending random GIFs 
                    from giphy API. If the selected GIF would be different from 
                    the previous selected one, then the score will increase by one, 
                    and a new set of random GIF will generate. And If the selected GIF 
                    is the same, the best score will be the current score, and the 
                    current score will turn to zero. 
                </p>
                <div className="project-page-work">
                    <h2 className="project-page-work--title">Accomplishments</h2>
                        <WorkDone  projectData={projects.get('sGIFs')} />
                </div>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('sGIFs')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('sGIFs')} />
                </div>
                <FeatureCreator projectData={projects.get('sGIFs')} />
            </Content>
        </Project>
    )
}

SuspectGifsPage.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default SuspectGifsPage
